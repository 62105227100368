import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

export default function ViewStock() {
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});

  const today = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const response = await axios.get('https://api.dremerz.net/api/shamarstock');
        var reverse = response.data.reverse();
        setStockData(reverse);
      } catch (error) {
        setError('Error fetching stock data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
  }, []);

  const handleDelete = async (productId) => {
    try {
      await axios.delete(`https://api.dremerz.net/api/shamarstock/${productId}`);
      setStockData(stockData.filter(stock => stock.id !== productId));
      alert('Stock deleted successfully!');
    } catch (error) {
      console.error('There was an error deleting the stock!', error);
      alert('Error deleting stock. Please try again.');
    }
  };

  const handleEdit = (index, stock) => {
    setEditIndex(index);
    setEditData(stock);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleSave = async (productId) => {
    try {
      await axios.patch(`https://api.dremerz.net/api/shamarstock/${productId}`, editData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setStockData(stockData.map(stock => (stock.id === productId ? { ...stock, ...editData } : stock)));
      setEditIndex(null);
      alert('Stock updated successfully!');
    } catch (error) {
      console.error('There was an error updating the stock!', error);
      alert('Error updating stock. Please try again.');
    }
  };

  const handleCancel = () => {
    setEditIndex(null);
    setEditData({});
  };

  const exportToExcel = () => {
    var newStockData = [];
    stockData.map((stock) => {
      newStockData.push({
        "Date": stock.date,
        "Product Name": stock.product_name,
        "H.S.N": stock.hsn,
        "Batch": stock.batch,
        "E.X.P DATE": stock.exp_date,
        "UNIT PRICE": stock.unit_price,
        "UNIT M.R.P": stock.mrp,
        "G.S.T": stock.gst,
        "TOTAL UNITS": stock.units,
        "SOLD UNITS": stock.sold || 0,
        "AVAILABLE UNITS": parseInt(stock.units) - (parseInt(stock.sold) || 0)
      }); 
    });
    const worksheet = XLSX.utils.json_to_sheet(newStockData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Data");
    XLSX.writeFile(workbook, `Stock_Data_${formattedDate}.xlsx`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="text-dark">
        <h2 className="mb-1 font-weight-bold" style={{ textTransform: "uppercase" }}>View Stock!</h2>
        <p className="text-small mb-0">{formattedDate}</p>
      </div>
      <br />
      <div className="card">
        <div className="card-body">
          <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
            <h4 className="card-title" style={{ textTransform: "uppercase" }}>Stock Table</h4>
            <button className='btn btn-primary' onClick={exportToExcel}>Export to Excel</button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table id="order-listing" className="table">
                  <thead>
                    <tr>
                      <th># S.NO</th>
                      <th>Date</th>
                      <th>Product Name</th>
                      <th>H.S.N</th>
                      <th>Batch</th>
                      <th>E.X.P DATE</th>
                      <th>UNIT M.R.P</th>
                      <th>G.S.T</th>
                      <th>TOTAL UNITS</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockData.map((stock, index) => (
                      <tr key={stock.id}>
                        <td>{index + 1}</td>
                        <td>{editIndex === index ? <input type="text" name="date" value={editData.date} onChange={handleInputChange} /> : stock.date}</td>
                        <td>{editIndex === index ? <input type="text" name="product_name" value={editData.product_name} onChange={handleInputChange} /> : stock.product_name}</td>
                        <td>{editIndex === index ? <input type="text" name="hsn" value={editData.hsn} onChange={handleInputChange} /> : stock.hsn}</td>
                        <td>{editIndex === index ? <input type="text" name="batch" value={editData.batch} onChange={handleInputChange} /> : stock.batch}</td>
                        <td>{editIndex === index ? <input type="text" name="exp_date" value={editData.exp_date} onChange={handleInputChange} /> : stock.exp_date}</td>
                        <td>{editIndex === index ? <input type="text" name="mrp" value={editData.mrp} onChange={handleInputChange} /> : stock.mrp}</td>
                        <td>{editIndex === index ? <input type="text" name="gst" value={editData.gst} onChange={handleInputChange} /> : stock.gst}</td>
                        <td>{editIndex === index ? <input type="text" name="units" value={(editData.units) <0 ? 0 : editData.units } onChange={handleInputChange} /> :( stock.units<0?0:stock.units)}</td>
                        <td style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                          {editIndex === index ? (
                            <>
                              <button className="btn btn-success" onClick={() => handleSave(stock.id)}>Save</button>
                              <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                            </>
                          ) : (
                            <>
                              <button className="btn btn-primary mr-2" onClick={() => handleEdit(index, stock)}>Edit</button>
                              <button className="btn btn-danger" onClick={() => handleDelete(stock.id)}>Delete</button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
