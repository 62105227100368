import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

export default function SaleSummary() {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const [expandedInvoice, setExpandedInvoice] = useState(null);

  const today = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get('https://api.dremerz.net/api/shamarclient');
        const reverse = response.data.reverse();
        setSalesData(reverse);
      } catch (error) {
        setError('Error fetching sales data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  const handleDelete = async (client_id) => {
    try {
      await axios.delete(`https://api.dremerz.net/api/shamarclient/${client_id}`);
      setSalesData(salesData.filter(sale => sale.id !== client_id));
      alert('Sale deleted successfully!');
    } catch (error) {
      console.error('There was an error deleting the sale!', error);
      alert('Error deleting sale. Please try again.');
    }
  };

  const handleEdit = (index, sale) => {
    setEditIndex(index);
    setEditData(sale);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleSave = async (client_id) => {
    try {
      await axios.patch(`https://api.dremerz.net/api/shamarclient/${client_id}`, editData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSalesData(salesData.map(sale => (sale.id === client_id ? { ...sale, ...editData } : sale)));
      setEditIndex(null);
      alert('Sale updated successfully!');
    } catch (error) {
      console.error('There was an error updating the sale!', error);
      alert('Error updating sale. Please try again.');
    }
  };

  const handleCancel = () => {
    setEditIndex(null);
    setEditData({});
  };

  const exportToExcel = () => {
    const newSalesData = salesData.map((sale, index) => ({
      "S.NO": index + 1,
      "Date": sale.date,
      "Product Name": sale.product_name,
      "SOLD UNITS": sale.units,
      "PRICE": parseFloat(sale.unit_price) * parseFloat(sale.units),
      "DISCOUNT": sale.discount,
      "GST": sale.gst,
      "SOLD COST": (parseFloat(sale.unit_price) * parseFloat(sale.units) - parseFloat(sale.discount)) + ((parseFloat(sale.unit_price) * parseFloat(sale.units) - parseFloat(sale.discount)) * parseFloat(sale.gst) / 100),
      "Client Name": sale.client_name,
      "Invoice Number": sale.invoice_number,
      "Mobile Number": sale.mobile_number,
    }));

    const worksheet = XLSX.utils.json_to_sheet(newSalesData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Data");
    XLSX.writeFile(workbook, `Sales_Data_${formattedDate}.xlsx`);
  };

  const toggleInvoice = (invoiceNumber) => {
    setExpandedInvoice(expandedInvoice === invoiceNumber ? null : invoiceNumber);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const groupedData = salesData.reduce((acc, sale) => {
    if (!acc[sale.invoice_number]) {
      acc[sale.invoice_number] = [];
    }
    acc[sale.invoice_number].push(sale);
    return acc;
  }, {});

  const calculateTotalCost = (sales) => {
    return sales.reduce((total, sale) => {
      const saleCost = (parseFloat(sale.unit_price) * parseFloat(sale.units) - parseFloat(sale.discount)) +
        ((parseFloat(sale.unit_price) * parseFloat(sale.units) - parseFloat(sale.discount)) * parseFloat(sale.gst) / 100);
      return total + saleCost;
    }, 0).toFixed(2);
  };

  return (
    <div>
      <div className="text-dark">
        <h2 className="mb-1 font-weight-bold" style={{ textTransform: "uppercase" }}>View Sales!</h2>
        <p className="text-small mb-0">{formattedDate}</p>
      </div>
      <br />
      <div className="card">
        <div className="card-body">
          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <h4 className="card-title" style={{ textTransform: "uppercase" }}>Sales Table</h4>
            <button className='btn btn-primary' onClick={exportToExcel}>Export to Excel</button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table id="order-listing" className="table">
                  <thead>
                    <tr>
                      <th># S.NO</th>
                      <th>Date</th>
                      <th>Product Name</th>
                      <th>SOLD UNITS</th>
                      <th>FREE UNITS</th>
                      <th>PRICE</th>
                      <th>DISCOUNT</th>
                      <th>GST</th>
                      <th>SOLD COST</th>
                      <th>Client Name</th>
                      <th>Invoice Number</th>
                      <th>Mobile Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(groupedData).map((invoiceNumber, index) => {
                      const totalCost = calculateTotalCost(groupedData[invoiceNumber]);
                      const productNames = groupedData[invoiceNumber].map(sale => sale.product_name).join(', ');

                      return (
                        <React.Fragment key={invoiceNumber}>
                          <tr onClick={() => toggleInvoice(invoiceNumber)} style={{ cursor: 'pointer' }}>
                            <td colSpan="12" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                              {` ${
                                '' + groupedData[invoiceNumber][0].date + ' '
                              } INV-NO: ${invoiceNumber} 
                              BILL-NAME : ( ---> ${groupedData[invoiceNumber][0].client_name} <--- )
                              ${groupedData[invoiceNumber][0].payment_due!=""?
                              "Due Date : ( *** "+groupedData[invoiceNumber][0].payment_due+" *** )":""  
                            }
                           
                              - Total: ₹${totalCost} - Products: ${
                               "(" + groupedData[invoiceNumber].length +")" +` ----->  (Click to ${expandedInvoice === invoiceNumber ? 'collapse' : 'expand'})`
                              }`}
                            </td>
                          </tr>
                          {expandedInvoice === invoiceNumber && groupedData[invoiceNumber].map((sale, subIndex) => (
                            <tr key={sale.client_id}>
                              <td>{index + 1}.{subIndex + 1}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="date" value={editData.date} onChange={handleInputChange} /> : sale.date}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="product_name" value={editData.product_name} onChange={handleInputChange} /> : sale.product_name}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="units" value={editData.units} onChange={handleInputChange} /> : sale.units}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="free" value={editData.free} onChange={handleInputChange} /> : sale.free}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="unit_price" value={editData.unit_price} onChange={handleInputChange} /> : parseFloat(sale.unit_price) * parseFloat(sale.units)}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="discount" value={editData.discount} onChange={handleInputChange} /> : sale.discount}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="gst" value={editData.gst} onChange={handleInputChange} /> : sale.gst} %</td>
                              <td>
                                {editIndex === index && editData.id === sale.id ? (
                                  (parseFloat(editData.unit_price) * parseFloat(editData.units) - parseFloat(editData.discount)) +
                                  ((parseFloat(editData.unit_price) * parseFloat(editData.units) - parseFloat(editData.discount)) * parseFloat(editData.gst) / 100)
                                ) : (
                                  (parseFloat(sale.unit_price) * parseFloat(sale.units) - parseFloat(sale.discount)) +
                                  ((parseFloat(sale.unit_price) * parseFloat(sale.units) - parseFloat(sale.discount)) * parseFloat(sale.gst) / 100)
                                )}
                              </td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="client_name" value={editData.client_name} onChange={handleInputChange} /> : sale.client_name}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="invoice_number" value={editData.invoice_number} onChange={handleInputChange} /> : sale.invoice_number}</td>
                              <td>{editIndex === index && editData.id === sale.id ? <input type="text" name="mobile_number" value={editData.mobile_number} onChange={handleInputChange} /> : sale.mobile_number}</td>
                              <td style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                {editIndex === index && editData.id === sale.id ? (
                                  <>
                                    <button className="btn btn-success" onClick={() => handleSave(sale.id)}>Save</button>
                                    <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                                  </>
                                ) : (
                                  <>
                                    <button className="btn btn-primary mr-2" onClick={() => handleEdit(index, sale)}>Edit</button>
                                    <button className="btn btn-danger" onClick={() => handleDelete(sale.id)}>Delete</button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
