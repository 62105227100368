import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';

export default function AddStock() {

  const [formData, setFormData] = useState({
    product_name: '',
    hsn: '',
    batch: '',
    exp_date: '',
    units: '',

    mrp: '',
    pts: '10',
    ptr: '20',
    gst: '12'
  });

  const [errors, setErrors] = useState({});
  const [stockNames, setStockNames] = useState([]);
  const [isProductNew, setIsProductNew] = useState(false);

  const today = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    for (const key in formData) {
      if (!formData[key]) {
        newErrors[key] = 'This field is required';
      }
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return console.log('Form validation failed:', newErrors);
    }
    let isoDate = new Date().toISOString().split('T')[0];
    let formattedDate = isoDate.split('-').reverse().join('/');
    // add date to form data in dd/mm/yyyy format
    formData.date = formattedDate;

    try {
      const existingProduct = stockNames.find(
        (product) => product.label.toLowerCase() === formData.product_name.toLowerCase()
      );

      if (!existingProduct) {
        setIsProductNew(true);
        // Create a new product if it doesn't exist
        const createProductResponse = await axios.post('https://api.dremerz.net/api/shamarstock', formData, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        console.log('New product created:', createProductResponse.data);
      } else {
        setIsProductNew(false);
        // Add stock data to the existing product
        const addStockResponse = await axios.post('https://api.dremerz.net/api/shamarstock', formData, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        console.log('Stock data added to existing product:', addStockResponse.data);
      }

      alert('Data submitted successfully!');
      setFormData({
        product_name: '',
        hsn: '',
        batch: '',
        exp_date: '',
        units: '',
   
        mrp: '',
        pts: '10',
        ptr: '20',
        gst: '12'
      });
      setErrors({});
    } catch (error) {
      console.error('There was an error submitting the data!', error);
      alert('Error submitting data. Please try again.');
    }
  };

  const handleCancel = () => {
    setFormData({
      product_name: '',
      hsn: '',
      batch: '',
      exp_date: '',
      units: '',

      mrp: '',
      pts: '10',
      ptr: '20',
      gst: '12'
    });
    setErrors({});
  };

  useEffect(() => {
    axios.get('https://api.dremerz.net/api/shamarstock')
      .then((res) => {
        console.log('Stock names:', res.data);
        const options = res.data.map((data) => ({
          label: data.product_name,
          key: data.id
        }));

        const uniqueOptions = options.filter((option, index, self) =>
          index === self.findIndex((t) => (
            t.label === option.label
          ))
        );

        setStockNames(uniqueOptions);
      });
  }, []);

  const handleAutocompleteChange = (event, value) => {
    if (value) {
      setFormData({ ...formData, product_name: value.label });
      fetchProductDetails(value.key);
    } else {
      setFormData({ ...formData, product_name: event.target.value });
    }
  };

  const handleAutocompleteInputChange = (event, value) => {
    setFormData({ ...formData, product_name: value });
  };

  const fetchProductDetails = async (productId) => {
    try {
      const response = await axios.get(`https://api.dremerz.net/api/shamarstock/${productId}`);
      const productData = response.data;
      setFormData((prevFormData) => ({
        ...prevFormData,
        hsn: productData.hsn
      }));
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  return (
    <div>
      <div className="text-dark">
        <h2 className="mb-1 font-weight-bold" style={{ textTransform: "uppercase" }}>Add New Stock!</h2>
        <p className="text-small mb-0">{formattedDate}</p>
      </div>
      <br />

      <div className="card">
        <div className="card-body">
          <h4 className="card-title" style={{ textTransform: "uppercase" }}>Enter Stock Data!</h4>
          <p className="card-description" style={{ textTransform: "uppercase" }}>Fill the below</p>
          <form className="forms-sample" onSubmit={handleSubmit}>
            {[
              { label: 'PRODUCT NAME', name: 'product_name' },
              { label: 'H.S.N CODE', name: 'hsn' },
              { label: 'BATCH', name: 'batch' },
              { label: 'E.X.P DATE', name: 'exp_date' },
              { label: 'UNITS', name: 'units' },
              { label: 'UNIT M.R.P', name: 'mrp' },
              { label: 'G.S.T %', name: 'gst' }
            ].map(({ label, name }) => (
              <div className="form-group row" key={name}>
                <label htmlFor={name} className="col-sm-3 col-form-label">
                  {label}
                </label>
                <div className="col-sm-9">
                  {name === 'product_name' ? (
                    <Box sx={{ minWidth: 250 }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="name-select"
                          options={stockNames}
                          getOptionLabel={(option) => option.label}
                          value={stockNames.find(option => option.label === formData.product_name) || null}
                          onChange={handleAutocompleteChange}
                          onInputChange={handleAutocompleteInputChange}
                          freeSolo
                          renderInput={(params) => <TextField {...params} label={label} />}
                        />
                      </FormControl>
                    </Box>
                  ) : (
                    <input
                      type="text"
                      className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
                      id={name}
                      name={name}
                      value={formData[name]}
                      onChange={handleChange}
                      placeholder={label}
                    />
                  )}
                  {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
                </div>
              </div>
            ))}

            <button type="submit" className="btn btn-primary mr-2">
              Submit
            </button>
            <button type="button" className="btn btn-light" onClick={handleCancel}>
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
