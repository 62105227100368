import React, { startTransition } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useState } from 'react';

import Shamar from "./images/shamar.png";
import { toWords } from 'number-to-words';
import TinosRegular from "./assets/Tinos-Regular.ttf"
import TinosItalic from "./assets/Tinos-Italic.ttf"
import TinosBold from "./assets/Tinos-Bold.ttf"
import TinosBoldItalic from "./assets/Tinos-BoldItalic.ttf"
import { useLocation } from 'react-router-dom';
import Sign from "./sign.png";

Font.register({ family: 'TinosRegular', src: TinosRegular, format: 'truetype' });
Font.register({ family: 'TinosItalic', src: TinosItalic, format: 'truetype' });
Font.register({ family: 'TinosBold', src: TinosBold, format: 'truetype' });
Font.register({ family: 'TinosBoldItalic', src: TinosBoldItalic, format: 'truetype' });




const MyDocument = ({ data }) => {
    console.log("popopopo",data);
    if (!data || !data.length) {
        return (
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.text}>No data available</Text>
                    </View>
                </Page>
            </Document>
        );
    }

    const invoiceData = data[0];

    function formatAmount(number) {
        return toWords(number);
      }
      


    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.watermarkContainer}>
                    <Image src={Shamar} />
                </View>

                <View style={{ position: "absolute", bottom: 25, width: "100%", left: 350 }}>
                    <Text style={{ fontFamily: "TinosRegular", fontSize: 8, color: "grey" }}>
                        Designed & Developed by Dremerz.com
                    </Text>
                </View>

                <View style={[styles.section, { borderWidth: 1, borderColor: "black", width: "100%", borderRadius: 10 }]}>
                    <View style={{ padding: 3 }}>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Text style={[styles.text, { color: "#597684" }]}>Invoice</Text>
                        </View>

                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 12 }}>SHAMAR PHARMACEUTICALS PRIVATE LIMITED</Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10 }}>
                                NO.2875, SHOP NO.1 FROM SOUTH EAST FACING, GROUND FLOOR LIG 2, TONAKELA CAMP ROAD TNHB, AYYAPAKKAM, CHENNAI TAMIL NADU (33) INDIA 600077
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10 }}>GST IN : 33ABMCS1392P1ZP / PAN : ABMCS1392P</Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10 }}>D.L.No: TN / TVR / 20B / 00836 / TN / TVR / 21B / 00836</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: 0 }}></View>

                    <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                        <View style={{ flex: 2.5, paddingHorizontal: 10, display: "flex", flexDirection: "column" }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 10, paddingBottom: 5 }}>Bill To:</Text>
                            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 0.3 }}>Billing Name:</Text>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 1, textTransform: "uppercase" }}>
                                    {invoiceData.clientName}
                                </Text>
                            </View>

                            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 0.3 }}>GST IN:</Text>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 1, textTransform: "uppercase" }}>
                                    {invoiceData.gstNumber || "NA"}
                                </Text>
                            </View>

                            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 0.3 }}>D.L.No:</Text>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 1, textTransform: "uppercase" }}>
                                    {invoiceData.dlt || "NA"}
                                </Text>
                            </View>

                            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 0.3 }}>Mobile Number:</Text>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 1, textTransform: "uppercase" }}>
                                    +91 {invoiceData.mobileNumber}
                                </Text>
                            </View>

                            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 0.3 }}>Billing Address:</Text>
                                <Text style={{ fontFamily: "TinosRegular", fontSize: 10, flex: 1, textTransform: "uppercase" }}>
                                    {invoiceData.address}.
                                </Text>
                            </View>
                        </View>

                        <View style={{ flex: 1 }}>
                            <View style={{ borderBottomWidth: 0, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", paddingHorizontal: 5 }}>
                                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", flex: 1 }}>
                                    <Text style={{ fontSize: 10, fontFamily: "TinosBold", flex: 1, textAlign: "left" }}>
                                        Invoice No :
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: "TinosRegular", flex: 1, textAlign: "left" }}>
                                        {invoiceData.invoiceNumber}
                                    </Text>
                                </View>
                            </View>

                            <View style={{ borderBottomWidth: 0, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", paddingHorizontal: 5 }}>
                                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", flex: 1 }}>
                                    <Text style={{ fontSize: 10, fontFamily: "TinosBold", flex: 1, textAlign: "left" }}>
                                        Date :
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: "TinosRegular", flex: 1, textAlign: "left" }}>
                                        {invoiceData.date}
                                    </Text>
                                </View>
                            </View>

                            <View style={{ borderBottomWidth: 0, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", paddingHorizontal: 5 }}>
                                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", flex: 1 }}>
                                    <Text style={{ fontSize: 10, fontFamily: "TinosBold", flex: 1, textAlign: "left" }}>
                                        Dispatched Through :
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: "TinosRegular", flex: 1, textAlign: "left", textTransform: "uppercase" }}>
                                        {invoiceData.dispatchedThrough}
                                    </Text>
                                </View>
                            </View>

                   

                            <View style={{ borderBottomWidth: 0, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", paddingHorizontal: 5 }}>
                                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", flex: 1 }}>
                                    <Text style={{ fontSize: 10, fontFamily: "TinosBold", flex: 1, textAlign: "left" }}>
                                        Payment Due :
                                    </Text>
                                    <Text style={{ fontSize: 10, fontFamily: "TinosRegular", flex: 1, textAlign: "left" }}>
                                        {invoiceData.paymentDue || "NA"}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={{ marginTop: 10 }}></View>

                    {/* Table Header */}
                    <View style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "row", borderTopWidth: 0.5 }}>
                        <View style={{ flex: 0.7, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>S.No</Text>
                        </View>
                        <View style={{ flex: 3.5, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Product Name</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>HSN</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Batch</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>EXP Date</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Units</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Free</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>M.R.P</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Unit Price</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Discount</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>GST%</Text>
                        </View>
                        {invoiceData.pts !== "0" && (
                            <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                                <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>PTS</Text>
                            </View>
                        )}
                        {invoiceData.ptr !== "0" && (
                            <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                                <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>PTR</Text>
                            </View>
                        )}
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Rate</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Total</Text>
                        </View>
                    </View>

                    {/* Table Body */}
                    <View style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "row", borderTopWidth: 0.5, borderBottomWidth: 0.5 }}>
                        <View style={{ flex: 0.7, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {index + 1}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 3.5, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {item.productName}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {item.hsn}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {item.batch}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {item.expDate}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {parseFloat(item.units).toFixed(2)}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {parseFloat(item.free || 0).toFixed(2)}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {parseFloat(item.mrp).toFixed(2)}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {parseFloat(item.unitPrice).toFixed(2)}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {parseFloat(item.discount).toFixed(2)}
                                </Text>
                            ))}
                        </View>

                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {parseFloat(item.gst).toFixed(0)} %
                                </Text>
                            ))}
                        </View>

              
                        {invoiceData.pts !== "0" && (
                            <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                                {data.map((item, index) => (
                                    <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                        {parseFloat(item.pts).toFixed(2)}
                                    </Text>
                                ))}
                            </View>
                        )}
                        {invoiceData.ptr !== "0" && (
                            <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                                {data.map((item, index) => (
                                    <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                        {parseFloat(item.ptr).toFixed(2)}
                                    </Text>
                                ))}
                            </View>
                        )}
                        <View style={{ flex: 1, borderRightWidth: 0.5, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {(parseFloat(item.unitPrice) * parseFloat(item.units)).toFixed(2)}
                                </Text>
                            ))}
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0, height: 200 }}>
                            {data.map((item, index) => (
                                <Text key={index} style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                    {(
                                      (  (parseFloat(item.unitPrice) * parseFloat(item.units))-parseFloat(item.discount) )+
                                        ((  (parseFloat(item.unitPrice) * parseFloat(item.units))-parseFloat(item.discount) ) * parseFloat(item.gst) / 100)
                                    ).toFixed(2)}
                                </Text>
                            ))}
                        </View>
                    </View>

                    <View style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}> </Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}> </Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}> </Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>

                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>GST %</Text>
                        </View>
                      
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Discount</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>

                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Taxable Value</Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>TAX</Text>
                        </View>
                    
                        
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 8, textAlign: "center", padding: 5 }}>Total</Text>
                        </View>
                    </View>

                    <View style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "row", borderTopWidth: 0.5, borderBottomWidth: 0.5 }}>
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}></Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}></Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}></Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                {parseFloat(invoiceData.gst).toFixed(2)}
                            </Text>
                        </View>
                    
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                               {
                                   data.reduce((acc, item) => acc + parseFloat(item.discount), 0).toFixed(2)
                                }
                               
                            </Text>
                        </View>
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                {data.reduce((acc, item) => acc +  ((parseFloat(item.unitPrice) * parseFloat(item.units))-parseFloat(item.discount)), 0).toFixed(2)}
                            </Text>
                        </View>
               
                        <View style={{ flex: 1, borderRightWidth: 0.5 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                {data.reduce((acc, item) => acc +  ((parseFloat(item.unitPrice) * parseFloat(item.units))-parseFloat(item.discount)) * parseFloat(item.gst) / 100, 0).toFixed(2)}
                            </Text>
                        </View>
                  
                     
                        <View style={{ flex: 1, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 8, textAlign: "center", padding: 5 }}>
                                {(
                                    data.reduce((acc, item) => acc + ((parseFloat(item.unitPrice) * parseFloat(item.units))-parseFloat(item.discount)), 0) +
                                    data.reduce((acc, item) => acc + (((parseFloat(item.unitPrice) * parseFloat(item.units))-parseFloat(item.discount)) * parseFloat(item.gst) / 100), 0) 
                                ).toFixed(2)}
                            </Text>
                        </View>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row", padding: 10 }}>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 10 }}>
                                Total Amount ( In Words )
                            </Text>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10 }}>
                                {formatAmount(
                               (
                                data.reduce((acc, item) => acc + ((parseFloat(item.unitPrice) * parseFloat(item.units))-parseFloat(item.discount)), 0) +
                                data.reduce((acc, item) => acc + (((parseFloat(item.unitPrice) * parseFloat(item.units))-parseFloat(item.discount)) * parseFloat(item.gst) / 100), 0) 
                            ).toFixed(2)
                                )} Only /-
                            </Text>
                        </View>

                        <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "flex-end" }}>
                            <Text style={{ fontFamily: "TinosBold", fontSize: 10 }}>
                                For SHAMAR PHARMACEUTICALS
                            </Text> 
                            <Image src={Sign} style={{ width: 100, position:"absolute",top:12 }} />
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10, marginTop: 30 }}>
                                Authorized Signatory
                            </Text>
                        </View>
                    </View>

                    <View style={{ paddingHorizontal: 10 }}>
                        <Text style={{ fontFamily: "TinosBold", fontSize: 10, paddingBottom: 7 }}>
                            Bank Details
                        </Text>
                        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10, width: 60 }}>Bank Name :</Text>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10, textTransform: "uppercase" }}>
                                Kotak Mahindra Bank
                            </Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10, width: 60 }}>Acc name :</Text>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10 }}>
                                SHAMAR PHARMACEUTICALS PRIVATE LIMITED
                            </Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10, width: 60 }}>Acc Number :</Text>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10 }}>
                                9749273690
                            </Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10, width: 60 }}>IFSC Code :</Text>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10 }}>
                                KKBK0008518
                            </Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10, width: 60 }}>Branch :</Text>
                            <Text style={{ fontFamily: "TinosRegular", fontSize: 10 }}>
                                AMBATTUR
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    section: {
        margin: 10,
        flexGrow: 1,
        width: "100%"
    },
    watermarkContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.5,
    },
    text: {
        fontFamily: 'TinosBold',
        fontSize: 18 // Added fontSize for better rendering
    }
});

export default function Home({ data }) {
    console.log("lolpopo", data);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfBlob, setPdfBlob] = useState(null);

    const sharePDF = () => {
        if (pdfUrl) {
            const encodedUrl = encodeURIComponent(pdfUrl);
            const whatsappUrl = `https://api.whatsapp.com/send?text=Check%20out%20this%20PDF:%20${encodedUrl}`;
            window.open(whatsappUrl, '_blank');
        }
    };
        
     return    (
data? <div style={{ width: "100%", height: "100%" }}>
                <PDFDownloadLink document={<MyDocument data={data} />} fileName="invoice.pdf">
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                </PDFDownloadLink>
              
        
    
                <PDFViewer style={{ width: "100%", height: "100%" }}>
                    <MyDocument data={data} />
                </PDFViewer>
            </div>
            :
            "Loading..."

        );
    
    
}
