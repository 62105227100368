import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Home from '../Home';
import { set } from 'date-fns';

export default function NewSale() {
  const formatDate = (e) => {
    const dateValue = new Date(e.target.value);
    const day = String(dateValue.getDate()).padStart(2, '0');
    const month = String(dateValue.getMonth() + 1).padStart(2, '0');
    const year = dateValue.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };


  const today = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  const [date, setDate] = useState('');
  const [product, setProduct] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState(null);
  const [clientName, setClientName] = useState([]);
  const [filteredClientName, setFilteredClientName] = useState(null);
  const [clientNumber, setClientNumber] = useState([]);
  const [filteredClientNumber, setFilteredClientNumber] = useState(null);
  const [saleFor, setSaleFor] = useState('retailer');
  const [dueDate, setDueDate] = useState('');
  const [dataList, setDataList] = useState([]);
  const [pdfData, setPdfData] = useState(false);
  const [gst, setGst] = useState('');
  const [discount, setDiscount] = useState('0');
  const [discount1, setDiscount1] = useState('0');
  const [dispatchedThrough, setDispatchedThrough] = useState('');

  const [formData, setFormData] = useState({
    clientName: '',
    mobileNumber: '',
    address: '',
    gstNumber: '',
    invoiceNumber: 'SHA' + Date.now(),
    paymentDue: dueDate,
    dispatchedThrough: dispatchedThrough,
    productName: '',
    hsn: '',
    batch: '',
    expDate: '',
    units: '',
    unitPrice: '',
    free: '0',
    mrp: '',
    pts: '',
    ptr: '',
    gst: '',
    dlt: '',
    discount: discount,
    total: '',
    saleFor: saleFor,
    date: formattedDate,
  });

  const handleChangeProduct = (event, newValue) => {
    setFilteredProduct(newValue);
    if (newValue) {
      setFormData({
        ...formData,
        productName: newValue.product_name,
        hsn: newValue.hsn,
        batch: newValue.batch,
        expDate: newValue.exp_date,
        units: newValue.units,
        free: newValue.free,
        gst: newValue.gst,
        mrp: newValue.mrp,
        date: formattedDate,
      });
    }
  };

  useEffect(() => {
    axios.get('https://api.dremerz.net/api/shamarstock')
      .then((res) => {
        const uniqueProducts = res.data.map((item) => ({
          label: item.product_name,
          value: item.product_name,
          product_name: item.product_name,
          hsn: item.hsn,
          batch: item.batch,
          exp_date: item.exp_date,
          units: '1',
          unit_price: item.unit_price,
          free: item.free,
          mrp: item.mrp,
          pts: item.pts,
          ptr: item.ptr,
          gst: item.gst,
        })).filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
        setProduct(uniqueProducts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios.get('https://api.dremerz.net/api/shamarclient')
      .then((res) => {
        const uniqueClients = res.data.map((item) => ({
          label: item.client_name,
          value: item.client_id,
          client_id: item.client_id,
          client_name: item.client_name,
          mobile_number: item.mobile_number,
          address: item.address,
          gst_number: item.gst_number,
          dlt: item.dlt,
        })).filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
        setClientName(uniqueClients);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios.get('https://api.dremerz.net/api/shamarclient')
      .then((res) => {
        const uniqueClientNumbers = res.data.map((item) => ({
          label: item.mobile_number,
          value: item.client_id,
          client_id: item.client_id,
          client_name: item.client_name,
          mobile_number: item.mobile_number,
          address: item.address,
          gst_number: item.gst_number,
          dlt: item.dlt,
        })).filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
        setClientNumber(uniqueClientNumbers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (saleFor === 'stockist') {
      setFormData({
        ...formData,
        unitPrice: (
          ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) - ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) * 20 / 100)) -
          (((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) - ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) * 20 / 100)) * 0.10)
        ).toFixed(2),
        pts: (
          ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) - ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) * 20 / 100)) -
          (((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) - ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) * 20 / 100)) * 0.10)
        ).toFixed(2),
        ptr: (
          ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) - ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) * 20 / 100))
        ).toFixed(2),
      });
    } else if (saleFor === 'retailer') {
      setFormData({
        ...formData,
        unitPrice: (
          ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) - ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) * 20 / 100))
        ).toFixed(2),
        ptr: (
          ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) - ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) * 20 / 100))
        ).toFixed(2),
        pts: "0",
      });
    } else if (saleFor === 'direct') {
      setFormData({
        ...formData,
        unitPrice: (
          ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) - ((parseFloat(formData.mrp) / (1 + (parseFloat(formData.gst) / 100))) * 20 / 100))
        ).toFixed(2),
        ptr: '0',
        pts: '0',
      });
    }
  }, [formData.units, formData.gst, saleFor, discount]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    if (!formData.productName || !formData.unitPrice || !formData.units || !formData.gst || !formData.clientName || !formData.mobileNumber ) {
      alert('Please fill all the fields!');
      return;
    }
    console.log(formData, "lolpopo");
    event.preventDefault();
    const newFormData = {
      ...formData,
      discount: discount,
    };
    setDataList([...dataList, newFormData]);
    setFilteredProduct(null);
    setDiscount('0');
    setDiscount1('0');
    setFormData({
      clientName: formData.clientName,
      mobileNumber: formData.mobileNumber,
      address: formData.address,
      gstNumber: formData.gstNumber,
      invoiceNumber: formData.invoiceNumber,
      paymentDue: dueDate,
      dispatchedThrough: dispatchedThrough,
      productName: '',
      hsn: '',
      batch: '',
      expDate: '',
      units: '',
      unitPrice: '',
      free: '0',
      mrp: '',
      pts: '',
      ptr: '',
      gst: '',
      dlt: formData.dlt,
      discount: '0',
      total: '',
      saleFor: saleFor,
      date: formattedDate,
    });
  };

  const loopPost = async () => {

var stockToUpdate = [];
    var isAvailable = true;
      var stock = await axios.get('https://api.dremerz.net/api/shamarstock')
      var stockData = stock.data
      var stockList = [];
      stockData.forEach((item) => {
        dataList.forEach((data) => {
          if (item.product_name === data.productName) {
            stockList.push(item);
          }
        })
      });
      dataList.forEach(async(item) => {
        let QuantityNeed = parseFloat(item.units) + parseFloat(item.free || 0);
        console.log(QuantityNeed);
        let QuantityCarry = 0;
        stockList.forEach((stock) => {
          if (stock.product_name === item.productName) {
            if (stock.units >= QuantityNeed) {
              QuantityCarry = QuantityNeed;
              QuantityNeed = 0;
            } else if ((stock.units < QuantityNeed) && (stock.units > 0)) {
              QuantityCarry = stock.units;
              QuantityNeed = QuantityNeed - stock.units;
            }
            stock.units = stock.units - QuantityCarry;
          }
       
        })
      if (QuantityNeed > 0) {
        isAvailable = false;
        console .log("Stock is not available for ----> ***"+ item.productName + "*** \n"+
        "Quantity Need: ***"+ QuantityNeed + "*** \n"
        );
      } else {
        isAvailable = true
        stockToUpdate.push(stockList);
      }
      });

     
if(isAvailable){
      try {
let isoDate = new Date().toISOString().split('T')[0];
let formattedDate = isoDate.split('-').reverse().join('/');

      const postPromises = dataList.map((item) => {
        console.log(item);
        const data = {
          client_id: item.client_id,
          client_name: item.clientName,
          mobile_number: item.mobileNumber,
          address: item.address,
          gst_number: item.gstNumber,
          invoice_number: item.invoiceNumber,
          payment_due: item.paymentDue,
          dispatched_through: item.dispatchedThrough,
          product_name: item.productName,
          hsn: item.hsn,
          batch: item.batch,
          exp_date: item.expDate,
          units: item.units,
          unit_price: item.unitPrice,
          free: item.free,
          mrp: item.mrp,
          pts: item.pts,
          ptr: item.ptr,
          gst: item.gst,
          discount: item.discount,
          dlt: item.dlt,
          date: formattedDate
        };

        return axios.post("https://api.dremerz.net/api/shamarclient", data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      });
      stockToUpdate[0].forEach(async (item,index) => {
        axios.put(`https://api.dremerz.net/api/shamarstock/${item.id}`, item)
          .then(async(res) => {
            if (index === stockToUpdate[0].length - 1) {
              await Promise.all(postPromises);
            
              alert("Sales created successfully (:");
            }
        

          })
          .catch((err) => {
            console.error(err);
            alert("Error updating sales ): ");
          });
      });

      setPdfData(true);
      setFormData({});
      setClientName([]);
      setClientNumber([]);
      setFilteredClientName(null);
      setFilteredClientNumber(null);
      setFilteredProduct(null);
    } catch (error) {
      console.error(error);
      alert("Error creating sales ): ");
    }

  }

  else
  {
    alert("Stock is not available for some products");
  }
    
  };

  return (
    <>
      {pdfData ? (
        <Home data={dataList} />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="text-dark">
              <h2 className="mb-1 font-weight-bold">Create New Sale!</h2>
              <p className="text-small mb-0">{formattedDate}</p>
            </div>
            <div>
              <label htmlFor="date">Date</label>
              <input
                onChange={(e) => {
                  setDate(formatDate(e));
                }}
                name="date"
                style={{ width: '200px' }}
                type="date"
                className="form-control"
              />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
            <select
              name="saleType"
              id="form"
              className="form-control"
              style={{ width: '200px', height: '55px' }}
              value={saleFor}
              onChange={(e) => {
                setSaleFor(e.target.value);
              }}
            >
              <option value="retailer">Retailer</option>
              <option value="stockist">Stockist</option>
              <option value="direct">Direct</option>
            </select>

            <div>
              <select name="duedate" id="gduedatest" className="form-control" style={{ width: '200px', height: '55px' }} onChange={(e) => {
                if (e.target.value === '0') {
                  setDueDate('');
                  setFormData({ ...formData, paymentDue: '' });
                }
                else if (e.target.value === '30') {
                  const date = new Date();
                  date.setDate(date.getDate() + 30);
                  setFormData({ ...formData, paymentDue: date.toISOString().split('T')[0] });
                }
                else if (e.target.value === '40') {
                  const date = new Date();
                  date.setDate(date.getDate() + 40);
                  setFormData({ ...formData, paymentDue: date.toISOString().split('T')[0] });
                }
                else if (e.target.value === '60') {
                  const date = new Date();
                  date.setDate(date.getDate() + 60);
                  setFormData({ ...formData, paymentDue: date.toISOString().split('T')[0] });
                }
                else if (e.target.value === '90') {
                  const date = new Date();
                  date.setDate(date.getDate() + 90);
                  setFormData({ ...formData, paymentDue: date.toISOString().split('T')[0] });
                }
              }}>
                <option value="0">Cleared</option>
                <option value="30">30 Days</option>
                <option value="40">40 Days</option>
                <option value="60">60 Days</option>
                <option value="90">90 Days</option>
              </select>
            </div>

            <Box id="lol" sx={{ minWidth: 250 }}>
              <FormControl fullWidth>
                <Autocomplete
                  id="product-select"
                  options={product}
                  getOptionLabel={(option) => option.label}
                  value={filteredProduct || null}
                  onChange={handleChangeProduct}
                  renderInput={(params) => <TextField {...params} label="Product" />}
                  freeSolo
                />
              </FormControl>
            </Box>
            <Box id="lol" sx={{ minWidth: 250 }}>
              <FormControl fullWidth>
                <Autocomplete
                  id="name-select"
                  options={clientName}
                  getOptionLabel={(option) => option.label}
                  value={filteredClientName || null}
                  onChange={(event, newValue) => {
                    setFilteredClientName(newValue);
                    if (newValue) {
                      setFormData({
                        ...formData,
                        clientName: newValue.client_name,
                        mobileNumber: newValue.mobile_number,
                        address: newValue.address,
                        gstNumber: newValue.gst_number,
                        dlt: newValue.dlt,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Client Name" />}
                  freeSolo
                />
              </FormControl>
            </Box>
            <Box id="lol" sx={{ minWidth: 250 }}>
              <FormControl fullWidth>
                <Autocomplete
                  id="number-select"
                  options={clientNumber}
                  getOptionLabel={(option) => option.label}
                  value={filteredClientNumber || null}
                  onChange={(event, newValue) => {
                    setFilteredClientNumber(newValue);
                    if (newValue) {
                      setFormData({
                        ...formData,
                        clientName: newValue.client_name,
                        mobileNumber: newValue.label,
                        address: newValue.address,
                        gstNumber: newValue.gst_number,
                        dlt: newValue.dlt,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Client Number" />}
                  freeSolo
                />
              </FormControl>
            </Box>
          </div>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="card">
              {saleFor !== 'direct' && (
                <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                  <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                    <label htmlFor="ptr">P.T.R</label>
                    <input type="text" className="form-control" id="ptr" name="ptr" value={formData.ptr} onChange={handleInputChange} />
                  </div>

                  {saleFor === 'stockist' && (
                    <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                      <label htmlFor="pts">P.T.S</label>
                      <input type="text" className="form-control" id="pts" name="pts" value={formData.pts} onChange={handleInputChange} />
                    </div>
                  )}
                </div>
              )}
              <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="productName">Product Name</label>
                  <input type="text" className="form-control" id="productName" name="productName" value={formData.productName} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '100px', textTransform: 'uppercase' }}>
                  <label htmlFor="unitPrice">UNIT Price</label>
                  <input type="text" className="form-control" id="unitPrice" name="unitPrice" readOnly value={parseFloat(formData.unitPrice).toFixed(2)} />
                </div>
                <div className="form-group" style={{ width: '150px', textTransform: 'uppercase' }}>
                  <label htmlFor="units">Product Q.T.Y</label>
                  <input type="text" className="form-control" id="units" name="units" value={formData.units} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '100px', textTransform: 'uppercase' }}>
                  <label htmlFor="free">Free Units</label>
                  <input type="text" className="form-control" id="free" name="free" value={formData.free} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '150px', textTransform: 'uppercase' }}>
                  <label htmlFor="total">Total Price</label>
                  <input
                    type="text"
                    className="form-control"
                    id="total"
                    name="total"
                    value={
                      formData.unitPrice && formData.units && formData.gst
                        ? ((parseFloat(formData.unitPrice) * parseFloat(formData.units))).toFixed(2)
                        : 0
                    }
                    readOnly
                  />
                </div>
                <div className="form-group" style={{ width: '100px', textTransform: 'uppercase' }}>
                  <label htmlFor="discount">Discount %</label>
                  <input type="text" className="form-control" id="discount" name="discount" value={discount1} onChange={(event) => {
                    const discountValue = (parseFloat(formData.unitPrice) * parseFloat(formData.units) * ((parseFloat(event.target.value) || 0) / 100)).toFixed(2);
                    setDiscount(discountValue);
                    setDiscount1(event.target.value);
                  }} />
                </div>
                <div className="form-group" style={{ width: '100px', textTransform: 'uppercase' }}>
                  <label htmlFor="gst">G.S.T %</label>
                  <input type="text" className="form-control" id="gst" name="gst" value={formData.gst} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '150px', textTransform: 'uppercase' }}>
                  <label htmlFor="netamount">Net amount</label>
                  <input
                    type="text"
                    className="form-control"
                    id="netamount"
                    name="netamount"
                    value={
                      formData.unitPrice && formData.units && formData.gst
                        ? (
                          (((parseFloat(formData.unitPrice) * parseFloat(formData.units)) - parseFloat(discount || 0)) +
                            ((parseFloat(formData.unitPrice) * parseFloat(formData.units) - parseFloat(discount || 0)) * parseFloat(formData.gst) / 100)
                          )).toFixed(2)
                        : 0
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="invoiceNumber">Invoice Number</label>
                  <input type="text" className="form-control" id="invoiceNumber" name="invoiceNumber" value={formData.invoiceNumber} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="clientName">Client Name</label>
                  <input type="text" className="form-control" id="clientName" name="clientName" value={formData.clientName} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="clientNumber">Client NUMBER</label>
                  <input type="text" className="form-control" id="clientNumber" name="mobileNumber" value={formData.mobileNumber} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="clientAddress">Client Address</label>
                  <input type="text" className="form-control" id="clientAddress" name="address" value={formData.address} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="dispatchedThrough">Dispatched Through</label>
                  <input type="text" className="form-control" id="dispatchedThrough" name="dispatchedThrough" value={formData.dispatchedThrough} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="gstNumber">GST NUMBER</label>
                  <input type="text" className="form-control" id="gstNumber" name="gstNumber" value={formData.gstNumber} onChange={handleInputChange} />
                </div>
                <div className="form-group" style={{ width: '200px', textTransform: 'uppercase' }}>
                  <label htmlFor="dlt">DL NUMBER</label>
                  <input type="text" className="form-control" id="dlt" name="dlt" value={formData.dlt} onChange={handleInputChange} />
                </div>
              </div>

              <div className="card-body" style={{ float: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                <button type="submit" className="btn btn-primary mr-2">Submit</button>
              </div>
            </div>
          </form>
          <br />
          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Unit Price</th>
                    <th>Product Q.T.Y</th>
                    <th>Free Units</th>
                    <th>Total Price</th>
                    <th>G.S.T %</th>
                    <th>G.S.T</th>
                    <th>Discount</th>
                    <th>Net Price</th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.productName}</td>
                      <td>{item.unitPrice}</td>
                      <td>{item.units}</td>
                      <td>{item.free || 0}</td>
                      <td>{(parseFloat(item.unitPrice) * parseFloat(item.units)).toFixed(2)}</td>
                      <td>{item.gst}</td>
                      <td>{(parseFloat(item.unitPrice) * parseFloat(item.units) * parseFloat(item.gst) / 100).toFixed(2)}</td>
                      <td>{item.discount}</td>
                      <td>{(
                        ((parseFloat(item.unitPrice) * parseFloat(item.units)) - parseFloat(item.discount)) +
                        (((parseFloat(item.unitPrice) * parseFloat(item.units)) - parseFloat(item.discount)) * parseFloat(item.gst) / 100)
                      ).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <div className="card-body" style={{ float: 'right', display: 'flex', justifyContent: 'flex-end' }}>
              <button type="submit" onClick={loopPost} className="btn btn-primary mr-2">Complete Sale</button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
